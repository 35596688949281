var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isJobTableLoading,
          expression: "isJobTableLoading",
        },
      ],
      staticClass: "div-main-list-page-table",
    },
    [
      _vm.isMobileScreen
        ? [
            _vm.actionList && _vm.$props.jobTableData
              ? _c("job-card-list", {
                  attrs: {
                    jobList: _vm.$props.jobTableData,
                    actionList: _vm.actionList,
                    hasTableMenu: _vm.$props.hasTableMenu,
                  },
                  on: {
                    getJobTableMenuList: _vm.getJobTableMenuList,
                    handleJobDropdownMenuClick: _vm.handleJobDropdownMenuClick,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      !_vm.isJobTableLoading && !_vm.isMobileScreen
        ? _c(
            "el-table",
            {
              ref: "jobTable",
              attrs: {
                data: _vm.jobTableData,
                height: "100%",
                border: true,
                "header-cell-style": _vm.HEADER_CELL_STYLE,
                "cell-style": _vm.CELL_STYLE,
              },
              on: { "selection-change": _vm.handleJobSelectionChange },
            },
            [
              _vm.hasCheckbox
                ? _c("el-table-column", {
                    attrs: { fixed: "", width: "40", type: "selection" },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: "Status",
                  width: "100",
                  "class-name": _vm.activeTab === 4 ? "mpa-status-cell" : "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.activeTab == 4 &&
                          scope.row.mpaTransactionStatus != null
                            ? _c(
                                "div",
                                { staticClass: "mpa-status-flag" },
                                [
                                  scope.row.mpaTransactionStatus
                                    .successfulCount > 0
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "MPA transaction successful",
                                            placement: "right-end",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "green" } },
                                            [_vm._v(" ✓ ")]
                                          ),
                                        ]
                                      )
                                    : scope.row.mpaTransactionStatus
                                        .pendingCount > 0
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "MPA transaction in progressing",
                                            placement: "right-end",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "grey" } },
                                            [_vm._v(" ... ")]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "MPA transaction failed",
                                            placement: "right-end",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(" 〤 ")]
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "status-tag",
                              style: _vm.getStatusTagStyle(
                                _vm.STATUS_MAPPING[scope.row.status].name
                              ),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.STATUS_MAPPING[scope.row.status].name
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2779197603
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Job Type", prop: "typeDisplay", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { label: "Link Order", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(!!scope.row?.orderId ? "Yes" : "No") + " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3382137850
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Company Name", width: "150" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.buyer ===
                                  _vm.currentCompany.companyName
                                  ? scope.row.seller || "-"
                                  : scope.row.buyer || "-"
                              ) + " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3106657524
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Vessel/Terminal", width: "150" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === 2
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.exWharfType === 0
                                      ? scope.row.locationLevel1 || "-"
                                      : scope.row.floaterName ||
                                          scope.row.locationLevel1 ||
                                          "-"
                                  )
                                ),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(scope.row.vesselName || "-")),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  762615580
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Vessel Imo", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.vesselImo || "-")),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2773681146
                ),
              }),
              _c("el-table-column", {
                staticClass: "order-date-column",
                attrs: {
                  label: "Stem Date",
                  prop: "orderDateDisplay",
                  width: "90",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.stemStartDate
                                    ? _vm
                                        .$moment(scope.row.stemStartDate)
                                        .format("YYYY-MM-DD")
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.stemEndDate
                                    ? _vm
                                        .$moment(scope.row.stemEndDate)
                                        .format("YYYY-MM-DD")
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4233029780
                ),
              }),
              _vm.isViewOnly || [3, 4, 5].includes(_vm.activeTab)
                ? _c("el-table-column", {
                    attrs: {
                      prop: "datetime",
                      label: "Vessel ETA/Loading Nom",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.datetime
                                        ? _vm
                                            .$moment(scope.row.datetime)
                                            .format("YYYY-MM-DD HH:mm")
                                        : "-"
                                    )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2091777542
                    ),
                  })
                : _c("el-table-column", {
                    attrs: {
                      prop: "datetime",
                      label: "Vessel ETA/Loading Nom",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetime",
                                  format: "yyyy-MM-dd HH:mm",
                                  disabled:
                                    [3, 4, 5].includes(scope.row.status) ||
                                    !_vm.isJobCreatorOrCustomerEditView(
                                      scope.row
                                    ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.rowValueChange(
                                      scope.row,
                                      "datetime"
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.datetime,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "datetime", $$v)
                                  },
                                  expression: "scope.row.datetime",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3621567950
                    ),
                  }),
              _vm.isViewOnly || [3, 4, 5].includes(_vm.activeTab)
                ? _c("el-table-column", {
                    attrs: {
                      prop: "datetime",
                      label: "Location/Berth",
                      width: "250",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.locationLevel1 &&
                                        scope.row.locationLevel2
                                        ? `${scope.row.locationLevel1} / ${scope.row.locationLevel2}`
                                        : scope.row.locationLevel1 ||
                                            scope.row.locationLevel2 ||
                                            "-"
                                    )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1875291433
                    ),
                  })
                : _c("el-table-column", {
                    attrs: { label: "Location/Berth", width: "230" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-cascader", {
                                key: _vm.cascaderKey,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  "popper-class": "el-cascader-long",
                                  options: _vm.locationList,
                                  props: _vm.locationOptionProps,
                                  disabled:
                                    [3, 4, 5].includes(scope.row.status) ||
                                    !_vm.isJobCreatorOrCustomerEditView(
                                      scope.row
                                    ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.rowValueChange(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.locationLevel,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "locationLevel", $$v)
                                  },
                                  expression: "scope.row.locationLevel",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4044963594
                    ),
                  }),
              _vm.isViewOnly || [3, 4, 5].includes(_vm.activeTab)
                ? _c("el-table-column", {
                    attrs: { label: "Barge", width: "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.bargeName || "-")),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2293853599
                    ),
                  })
                : _c("el-table-column", {
                    attrs: { label: "Barge", width: "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.mitigatingCompanyOrgId ===
                              _vm.currentCompany.id
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: scope.row.type !== 3,
                                        "show-overflow-tooltip": "",
                                        disabled:
                                          [2, 3, 4, 5].includes(
                                            scope.row.status
                                          ) ||
                                          !_vm.isJobCreatorOrCustomerEditView(
                                            scope.row
                                          ),
                                        placeholder: "",
                                        loading: _vm.availableBargesLoading,
                                      },
                                      on: {
                                        change: (val) =>
                                          _vm.handleChangeBarge(val, scope.row),
                                        "visible-change": function ($event) {
                                          return _vm.visibleChange(
                                            $event,
                                            scope.row
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.bargeName,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "bargeName", $$v)
                                        },
                                        expression: "scope.row.bargeName",
                                      },
                                    },
                                    _vm._l(
                                      _vm.availableBargesOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            disabled:
                                              scope.row.type === 3 &&
                                              ((item || {}).id ==
                                                scope.row.vesselShipId ||
                                                (item.imo &&
                                                  item.imo ==
                                                    scope.row.vesselImo)),
                                            label: item.shipName,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: scope.row.bargeName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "bargeName", $$v)
                                      },
                                      expression: "scope.row.bargeName",
                                    },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3695639338
                    ),
                  }),
              _c("el-table-column", {
                attrs: { label: "Product/Grade/Specs", width: "250" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.productDisplayName || "-")),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4029023997
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Quantity", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.quantity || "-")),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  533452624
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Remark", "min-width": "250" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "textarea",
                              placeholder: "Please input your remarks",
                              disabled:
                                [3, 4, 5].includes(scope.row.status) ||
                                _vm.isViewOnly ||
                                !_vm.isJobCreatorOrCustomerEditView(scope.row),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.rowValueChange(scope.row, "remark")
                              },
                            },
                            model: {
                              value: scope.row.remark,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "remark", $$v)
                              },
                              expression: "scope.row.remark",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2800803463
                ),
              }),
              _vm.hasTableMenu
                ? _c("el-table-column", {
                    attrs: { width: "50px", align: "center", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("dropdown-action-menu", {
                                attrs: {
                                  actionList: _vm.getJobTableMenuList(
                                    scope.row
                                  ),
                                  propsData: scope.row,
                                },
                                on: {
                                  handleDropdownMenuClick:
                                    _vm.handleJobDropdownMenuClick,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3747224376
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.hasPagination && !_vm.isJobTableLoading
        ? _c(
            "div",
            { staticClass: "div-main-list-page-table-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.jobPagination.pageSize,
                  "page-sizes": [5, 10, 20, 50, 100],
                  "current-page": _vm.jobPagination.pageNumber,
                  layout: _vm.paginationSetting,
                  total: _vm.jobPagination.total,
                },
                on: {
                  "size-change": _vm.handleJobPageSizeChange,
                  "current-change": _vm.handleJobPageChange,
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.jobPagination, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.jobPagination, "pageSize", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }